import { observable } from 'mobx';
import { isEmpty, isString } from 'lodash';
import Chance from 'chance';
import Store from '../various/store';
import { CardStatus } from '../various/enums';
import authStore from './auth';
import { hasError } from '../utils/error';
import stripeStore from './stripe';
import Financial from '../utils/financial';
import userStore from './user';
import { wait } from '../utils/async';
import { dispatchPixel } from '../utils/pixel';
import captchaStore from './captcha';
import loaderStore from './loader';

class CardStore extends Store {
  @observable brand

  @observable captcha

  @observable cvc

  @observable detachable

  @observable expDate

  @observable expMonth

  @observable expYear

  @observable idempotency

  @observable last4

  @observable number

  @observable owner

  @observable status

  @observable recaptchaLoaded = false

  @observable recaptcha;

  factory() {
    this.brand = '';
    this.cvc = '';
    this.expDate = '';
    this.expMonth = '';
    this.expYear = '';
    this.idempotency = '';
    this.last4 = '';
    this.number = '';
    this.owner = '';
    this.status = CardStatus.PENDING;
  }

  /**
   * Gets the payment details of the logged user
   *
   * @returns {boolean}
   */
  async get(poll = false) {
    let card;

    for (let i = 0; i < (poll ? 10 : 1); i++) {
      // Get the credit card information if existing from the server
      card = await Financial.get('findCard', authStore.token);
      if (hasError(card)) return this.set('status', CardStatus.SUCCESS_EMPTY);
      if (this.last4.length > 0 ? this.last4 !== card.last4 : card) break;
      await wait(1000);
    }

    if (!card) return this.set('status', CardStatus.SUCCESS_EMPTY);

    // Create a key with the full expiry date for the form
    card.expDate = `${card.expMonth} / ${card.expYear}`;
    // Create a key for the cvc
    card.cvc = 'XXX';

    // Append the X to the number
    card.number = `•••• •••• •••• ${card.last4}`;

    Object.entries(card).forEach(([key, value]) => this.set(key, value));

    // Set the status to SUCCESS_EMPTY to inform the rest of the app that this is just a placeholder
    this.set('status', CardStatus.SUCCESS);

    return true;
  }

  /**
   * Updates the logged user registered credit card
   *
   * @returns {boolean}
   */
  async save() {
    const recaptcha = await captchaStore.getRecaptcha(this.isRecaptchaEnabled);
    if (recaptcha.status === 'EXPIRED') return false;

    loaderStore.show('Loader.saveCard');
    const secret = await Financial.get('/getClientSecretForCardSetup', authStore.token);
    if (hasError(secret)) { loaderStore.hide(); return false; }

    const stripe = await stripeStore.setup(secret);
    if (hasError(stripe)) { loaderStore.hide(); return false; }

    const payload = stripe.setupIntent.payment_method;
    const response = await Financial.post('/saveCard', payload, authStore.token, {
      'content-type': 'text/plain',
      'Captcha-Token': recaptcha.token,
    });
    if (hasError(response)) { loaderStore.hide(); return false; }
    dispatchPixel('save-card');
    // Set the status to pending to hard refresh the page because of a stripe bug
    this.set('status', CardStatus.PENDING);
    userStore.updateFunnel('credit_card_saved');

    loaderStore.hide();
    return true;
  }

  get isRecaptchaEnabled() {
    return Number(userStore.configurations?.['frontEnd.public.cardForm.recaptcha.enabled']) > 0
        && !navigator.userAgent.includes('GabriPi');
  }

  async update() {
    const recaptcha = await captchaStore.getRecaptcha(this.isRecaptchaEnabled);
    if (recaptcha.status === 'EXPIRED') return false;

    const secret = await Financial.get('/getClientSecretForCardSetup', authStore.token);
    if (hasError(secret)) return false;

    const stripe = await stripeStore.setup(secret);
    if (hasError(stripe)) return false;

    const payload = {
      idempotencyId: this.idempotency,
      paymentMethodId: stripe.setupIntent.payment_method,
    };
    const response = await Financial.post('/changeCard', payload, authStore.token, {
      'content-type': 'application/json',
      'Captcha-Token': recaptcha.token,
    });
    if (hasError(response)) return false;

    // Set the status to pending to hard refresh the page because of a stripe bug
    this.set('status', CardStatus.PENDING);

    return true;
  }

  /**
   * Removes the credit-card that the logged user has previously registed
   *
   * @returns {boolean}
   */
  async remove() {
    const response = await Financial.delete('detachCard', null, authStore.token);
    if (hasError(response)) {
      return false;
    }
    const emptyCard = {
      number: '',
      brand: '',
      expDate: '',
      expMonth: '',
      expYear: '',
      owner: '',
      cvc: '',
    };
    Object.entries(emptyCard).forEach(([key, value]) => this.set(key, value));

    this.set('status', CardStatus.PENDING);

    return true;
  }

  generateIdempotency() {
    this.set('idempotency', new Chance().guid());
  }

  get isChangeable() {
    return this.status === CardStatus.SUCCESS_CHANGE;
  }

  get isDetachable() {
    return this.detachable === true;
  }

  /**
   * Check if the credit card has been saved or not
   *
   * @returns {boolean}
   */
  get isSaved() {
    const { number, brand, expDate } = this;
    return isString(number)
      && !isEmpty(number)
      && isString(brand)
      && !isEmpty(brand)
      && isString(expDate)
      && !isEmpty(expDate);
  }

  get isSuccess() {
    return this.status === CardStatus.SUCCESS;
  }
}

const cardStore = new CardStore();
window.cardStore = cardStore;
export default cardStore;
