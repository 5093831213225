import { action, observable } from 'mobx';
import t from './translate';
import Err from '../utils/error';
import toastStore from './toast';
import Store from '../various/store';
import Financial from '../utils/financial';
import authStore from './auth';
import userStore from './user';
import { injectScript } from '../utils/script';

class StripeStore extends Store {
  @observable entity;

  @observable info;

  @observable type;

  factory() {
    this.entity = null;
    this.type = 'credit_card';
  }

  init() {
    injectScript({ src: 'https://js.stripe.com/v3/' });
  }

  /**
   * Creates a credit-card stripe token
   *
   * @param {string} name
   * @returns {object}
   */
  @action
  async token(name) {
    let token;

    token = await this.entity.createToken({ name });
    if (token.error) {
      toastStore.error(`Stripe.${token.error.code}`);
      return Err.create(token.error);
    }

    return token.token;
  }

  @action
  async setup(secret) {
    let result;

    result = await this.entity.handleCardSetup(secret);
    if (result.error) {
      const message =
        t.get(`Error.Stripe.${result.error.code}`) || result.error.message;

      toastStore.error(message, null, null, null);
      return Err.create(result.error);
    }

    return result;
  }

  /**
   * Creates a bank account stripe token
   *
   * @param {object} data
   * @returns {object}
   */
  @action
  async bank(data) {
    const token = await this.entity.createToken('bank_account', {
      account_holder_name: data.name,
      currency: this.info.marketCurrency,
      country: userStore.user.country,
      account_number: data.accountNumber,
      ...(data.routingNumber ? { routing_number: data.routingNumber } : {}),
    });
    if (token.error) {
      toastStore.error(`Stripe.${token.error.code || token.error.type}`);
      return Err.create(token.error);
    }

    return token.token;
  }

  /**
   * Handle card payment via secret
   *
   * @param secret
   * @returns {Promise<*>}
   */
  async handleAction(secret) {
    const intent = await this.entity.handleCardAction(secret);
    if (intent.error) {
      toastStore.error(`Stripe.${intent.error.code}`);
      return Err.create(intent.error);
    }

    return intent.paymentIntent;
  }

  async getStripeInfo() {
    const info = await Financial.get('/getStripeInfo', authStore.token);
    if (Err.check(info)) return false;
    this.set('info', info);
    return true;
  }

  get isBacs() {
    return ['GBP', 'USD'].some(
      (currency) => currency === this.info.marketCurrency
    );
  }
}

const stripeStore = new StripeStore();
export default stripeStore;
